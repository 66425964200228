import {Box, Typography} from "@mui/material";
import "./aboutEventSection.css"
import {getRightClass} from "../../utils/cssUtils";
import {useContext} from "react";
import {Context} from "../../App";


const AboutEventSection = ({eventData}) => {

    const {dimensions} = useContext(Context)


    return <Box className={getRightClass(
        'main-about__fulldescription-mobile',
        'main-about__fulldescription-tablet',
        'main-about__fulldescription-desktop',
        dimensions
    )}>
            <Typography className={"main-about__description"}> Описание </Typography>
            <Typography className={"main-about__course"} variant={'h7'}>
                <Box dangerouslySetInnerHTML = {{ __html: eventData.fulldescription }}></Box>
            </Typography>
    </Box>
}


export default AboutEventSection;