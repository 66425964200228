export function getGenitiveMonthName(date) {
    const formatter = new Intl.DateTimeFormat('ru-RU', {month: 'long', day: 'numeric'});
    const parts = formatter.formatToParts(date);
    return parts.find(part => part.type === 'month').value;
}

export function getDayAtStartOfDay(today) {
    return new Date(today.setHours(0, 0, 0, 0))
}

/* Добавляем месяцы к выбранной дате */
export const addMonths = (date, number) => {
    return new Date(date.setMonth(date.getMonth() + number));
}

/* Отнимаем месяцы у выбранной даты */
export const subtractMonths = (date, number) => {
    return new Date(date.setMonth(date.getMonth() - number));
}

export const getMonthYearString = (date) => {
    if (!date) return;
    return `${(date.getMonth() + 1).toString().padStart(2, '0')}${date.getFullYear()}`
}

/* Если сегодня нет запланированных мероприятий, то показываем ближайшую дату с событиями  */
export const getClosestDateWithEvents = (data) => {
    let closestDay = null;
    const today =  getDayAtStartOfDay(new Date());
    data.events.forEach(e => {
        if (today <= getDayAtStartOfDay(new Date(e.formatdate)) && (closestDay == null ||
            getDayAtStartOfDay(new Date(e.formatdate)) < closestDay)) {
            closestDay = getDayAtStartOfDay(new Date(e.formatdate));
        }
    });
    return closestDay;
}