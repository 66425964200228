import React from 'react';
import {Box, Grid2, Typography} from "@mui/material";
import {lightestGrey} from "../../utils/cssUtils";

const NotFoundComponent = ({text}) => {
    return (
        <Box>
            <Grid2 container>
                <Grid2 item size={12} display={'flex'} justifyContent={'center'} textAlign={'center'}>
                    <Typography variant={'h3'} mb={{xs: '100px'}} color={lightestGrey}>{text}</Typography>
                </Grid2>
            </Grid2>

        </Box>
    );
}

export default NotFoundComponent;