import {Box, Typography} from "@mui/material";
import {darkFont} from "../../utils/cssUtils";
import {getGenitiveMonthName} from "../../utils/dateUtils";
import {Fragment} from "react";
import CalendarEventDetails from "../calendarEventDetails/CalendarEventDetails";

const CalendarEvents = ({selectedDay, filteredData}) => {
    return <Fragment>
        <Typography variant={'h5'}>
            <Box color={darkFont} sx={{fontSize: '32px'}}>
                {selectedDay && `${selectedDay.getDate()} ${getGenitiveMonthName(selectedDay)}`}
            </Box>
        </Typography>
        <Box sx={{overflowY: 'auto', maxHeight: {xl:'400px',sm:'260px', xs:'400px'}, paddingTop: '10px'}}>
            {filteredData.map((d, index) => {
                return <CalendarEventDetails
                    key={index}
                    name={`${d.typename}: ${d.name}`}
                    time={d.formattime}
                    location={d.cityname}
                />
            })}
        </Box>
    </Fragment>
}

export default CalendarEvents;