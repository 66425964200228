import './App.css';
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";

import {createTheme, useMediaQuery} from "@mui/material";
import {ThemeProvider} from "@mui/material/styles";
import EventsPage from "./components/pages/EventsPage";
import EventPage from "./components/pages/EventPage";
import {createContext, useEffect, useState} from "react";
export const Context = createContext();

function App() {
    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(min-width:601px) and (max-width:900px)');
    const isDesktop = !isTablet && !isMobile;

    const theme = createTheme({
        typography: {
            fontFamily: '"VTBGroupUI", sans-serif',
        }
    });


    const [dimensions, setDimensions] = useState({isMobile, isTablet, isDesktop});

    return (
        <Context.Provider value={{dimensions, setDimensions}}>
            <ThemeProvider theme={theme}>
                <Router>
                    <Routes>
                        <Route path="/" element={<EventsPage />} />
                        <Route path="/:hash" element={<EventPage />} />
                    </Routes>
                </Router>
            </ThemeProvider>
        </Context.Provider>
    );
}

export default App;
