import api from "./api";
import {getBaseUrl, getData} from "../utils/httpUtils";

const jsonService = {

    getFiltersData: async () => {
        const filtersData = await api.getFiltersByUrl();
        try {
           return Object.values(JSON.parse(filtersData).filters)
        } catch (e) {
            console.log('Unable to parse json');
            return null;
        }
    },

    getEventsData: async (hash) => {
        const result = await api.getEventByUrl(hash);
        let eventObj = null;
        try{
            eventObj = JSON.parse(result);
        } catch(error){
            console.error('Unable to parse json')
            return {};
        }
        return eventObj;
    },

    getMonthEventsData: async (monthString) => {
        const result = await api.getMonthByUrl(monthString);
        let monthObj = null;
        try {
            monthObj = JSON.parse(result);
        } catch (e) {
            console.log('Unable to parse json');
            return null;
        }
        return monthObj;
    },

    getSpeakerImage: async (relativePath) => {
        return await api.getSpeakerImageByUrl(relativePath);
    },

    getAllSpeakerImagesMap: async (speakers) => {
        return await Promise.all(
            speakers.map(async (s) => {
                const imgUrl = await jsonService.getSpeakerImage(s.avatar);
                return {hash: s.hash, imgUrl: imgUrl, isLoading: false}
            })
        );
    },

}

export default jsonService;