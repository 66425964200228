import React, {useContext} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import img from "../../assets/images/logoblue.svg";
import imgmobile from "../../assets/images/logowhite.svg";
import './eventHeader.css';
import {Breadcrumbs, Button, Grid2, Link} from "@mui/material";
import {whiteColor, getRightClass} from "../../utils/cssUtils";
import {Context} from "../../App";
import imgDesktop from "../../assets/images/afisha2.jpg"
import imgMobile from "../../assets/images/afisha3.png"
import imgTablet from "../../assets/images/afisha2.jpg"


const EventHeader = ({eventData}) => {

    const {dimensions} = useContext(Context)


    /* Рендрим navbar страницы в зависимости от размера экрана */
    const renderUpperToolBar = () => {
        if (dimensions.isMobile || dimensions.isTablet ) {
            return <Link underline="hover" href="/">
            <Box>
                <img
                    alt=''
                    className={'upper-mobile-logo'}
                    width={'98px'}
                    height={'35px'}
                    src={imgmobile}/>
               </Box>
            </Link>
        }
        return <AppBar position="static"
                       sx={{backgroundColor: whiteColor, boxShadow: 'none'}}>

                <Link underline="hover" color="white" href="/">
                    <Toolbar>
                    <img
                        alt=''
                        className={dimensions.isMobile ? 'img-class-mobile' : dimensions.isTablet ? 'img-class-tablet' : 'img-class-desktop'}
                        width={'98px'}
                        height={'35px'}
                        style={{ cursor: 'pointer' }}
                        src={img}/>
                    <Typography  className={'WTF'} variant={"h6"} component="div"
                                 sx={{color: 'black', fontWeight: 'bold', fontSize: "15px"}} style={{ cursor: 'pointer' }}
                    >
                        Афиша мероприятий
                    </Typography>
                    </Toolbar>
                </Link>


        </AppBar>
    }

    /* Рендрим хлебные крошки страницы в зависимости от размера экрана */
    const renderBreadCrumbs = () => {

        return <Breadcrumbs aria-label="breadcrumb"
                            color={'white'}
                            className={dimensions.isTablet ? 'tablet-breadcrumbs' : null}>
            <Link underline="hover" color="white" href="/">
                Афиша мероприятий
            </Link>
            {!dimensions.isTablet && <Typography>
                {eventData.topic}
            </Typography>}
        </Breadcrumbs>
    }

    /* Рендрим хлебные крошки страницы в зависимости от размера экрана */
    const  redirectToRegistration = () => {
        return window.open(`${eventData.link}`, '_blank');
    }

    /* Заглушка для баннера */
    const getDefaultImgUrlDependingOnMedia = () => {
        return dimensions.isMobile ?
            `url(${imgMobile}) 50% 0 / cover no-repeat` :
            dimensions.isTablet ?
                `url(${imgTablet}) 60% 0 / cover no-repeat` :
            `url(${imgDesktop}) 0 / cover no-repeat`;
    }

    return (
        <Box>
            {renderUpperToolBar()}
            <Box className={dimensions.isMobile ? 'main-eventheader-mobile' : dimensions.isTablet ? 'main-eventheader-tablet' : 'main-eventheader-desktop' }
                sx={{background: eventData.banner ?
                        `url('${eventData.banner}') 0 / cover no-repeat` :
                        `${getDefaultImgUrlDependingOnMedia()}`}}
            >

                <Box className={getRightClass(
                    'main-eventheader__text-mobile',
                    'main-eventheader__text-tablet',
                    'main-eventheader__text-desktop',
                    dimensions
                    )}>
                    {!dimensions.isMobile  && renderBreadCrumbs()}
                <Grid2 className={getRightClass(
                    'main-eventheader__text-container-mobile',
                    'main-eventheader__text-container-tablet',
                    'main-eventheader__text-container-desktop', dimensions)}>
                    <Typography color={'white'}
                                className={getRightClass(
                                    'main-eventheader__text-header-mobile',
                                    'main-eventheader__text-header-tablet',
                                    'main-eventheader__text-header-desktop', dimensions)}>
                        {eventData.topic}
                    </Typography>
                    <Typography color={'white'}
                                className={getRightClass(
                                    "main-eventheader__text-description-mobile",
                                    "main-eventheader__text-description-tablet",
                                    "main-eventheader__text-description-desktop", dimensions)}>
                        {eventData.description}
                    </Typography>
                </Grid2>

                </Box>
                {eventData.link ? <Button variant={'contained'} className={getRightClass(
                    'eventheader__button-mobile',
                    'eventheader__button-tablet',
                    'eventheader__button-desktop', dimensions)}
                         onClick={redirectToRegistration}
                >
                    Зарегистрироваться
                </Button> : null}
            </Box>
        </Box>
    )
};

export default EventHeader;