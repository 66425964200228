import {Grid2, Typography} from "@mui/material";
import './mainFooter.css';
import img from "../../assets/images/logoblue.svg";
import {footerColor} from "../../utils/cssUtils";


const MainFooter = () => {


    return (
        <Grid2 container
               sx={{
                   height: {xl:'83px', xs: '72px'}
               }}
        >
            <Grid2 size={{xl: 6, lg:6, xs:3}} sx={{display: 'flex', justifyContent: 'start', paddingLeft: {xl:'200px', lg: '200px', sm:'40px', xs:'16px'}, mt: "24px"}}>
                <img
                    alt=''
                    className={'img-class__footer'}
                    src={img}/>
            </Grid2>
            <Grid2 size={{xl: 6, lg:6, xs:9}} sx={{display: 'flex', justifyContent: 'end', paddingRight: {xl:'200px', lg: '200px', sm:'40px', xs: '27px'}, mt: {xl:'31px', xs: '25px'}}}>
                <Typography
                    className={'footer-copyright'} color={footerColor}>
                    © ВТБ, 2024
                </Typography>
            </Grid2>
        </Grid2>

    )

}

export default MainFooter;