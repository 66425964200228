import {Grid2} from "@mui/material";
import './filterSection.css';
import CustomButtonGroup from "../customButtonGroup/CustomButtonGroup";
import MobileFilter from "../mobileFilter/MobileFilter";
import {FilterItem} from "../filterItem/FilterItem";
import {useState} from "react";


const FilterSection = ({data, isTablet, pressedButton, setPressedButton, setFilterCriteria, filterCriteria,isMacAir, isMobile}) => {

    const [filterItemFilterValueMap, setFilterItemFilterValueMap] = useState(data.filters.reduce((acc, f) => {
        acc[f.id] = -1;
        return acc;
    }, {}));



    /* Обработка события select */
    const handleFilterCriterionChange = (optionId, filterId) => {
        const newObj = {filterId, optionId};
        let newArr = structuredClone(filterCriteria);
        newArr = newArr.filter(filterCriterion => filterCriterion.filterId !== filterId)
        newArr.push(newObj)
        setFilterCriteria(newArr)

    }

    return (
        <Grid2 container sx={{marginBottom: '50px'}} spacing={(isTablet|| isMacAir) ? 1 : 0}>
            {(!isTablet && !isMacAir) && <Grid2  item size={{xl: 12, lg: 4, sm: 4, xs: 12}}>
                <CustomButtonGroup setPressedButtonInGroup={setPressedButton}
                                   pressedButtonInGroup={pressedButton}
                                   buttonName1={'Предстоящие'} buttonName2={'Прошедшие'}/>
            </Grid2>}

            {isMobile && (
                <MobileFilter
                    filterItemFilterValueMap={filterItemFilterValueMap}
                    setFilterItemFilterValueMap={setFilterItemFilterValueMap}
                    data={data}
                    handleFilterCriterionChange={handleFilterCriterionChange}
                />
            )}

            {!isMobile && data.filters.map((filterItem) => {
                return <FilterItem
                    isTablet={isTablet}
                    isMacAir={isMacAir}
                    filterItemFilterValueMap={filterItemFilterValueMap}
                    setFilterItemFilterValueMap={setFilterItemFilterValueMap}
                    id={filterItem.id}
                    filterItem={filterItem}
                    handleFilterCriterionChange={handleFilterCriterionChange}
                />
            })}
        </Grid2>

    );
};

export default FilterSection;