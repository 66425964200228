import {useContext, useEffect, useState} from "react";
import {Avatar, Box, CardContent, Grid2, Typography} from "@mui/material";
import {
    auxiliaryText,
    radius
} from "../../utils/cssUtils";
import './aboutSpeakersSection.css';
import {Context} from "../../App";
import jsonService from "../../services/jsonService";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
const AboutSpeakersSection = ({speaker}) => {

    const {dimensions} = useContext(Context);
    const [speakerImgUrl, setSpeakerImgUrl] = useState(null);

    useEffect(() => {
        async function func() {
            const imgUrl = await jsonService.getSpeakerImage(speaker.avatar);
            setSpeakerImgUrl(imgUrl)
        }
        func();
    }, []);

    const renderContents = () => {
        if (dimensions.isDesktop ) return <Grid2 container spacing={0} rowSpacing={0} >
            <Grid2 size={2} sx={{
                paddingTop: '32px',
                paddingBottom: '16px',
                paddingLeft: '32px',

            }}>
                <Avatar  variant={'square'}
                         sx={{width: 120, height: 120, borderRadius: radius}}
                >
                    {speakerImgUrl ? <Box width={'120px'}
                                       height={'120px'}
                                       sx={{
                                           backgroundImage: `url(${speakerImgUrl})`,
                                           backgroundSize: 'cover'
                                       }}>

                    </Box> : <AccountBoxIcon/>}

                </Avatar>

            </Grid2>
            <Grid2 container size={9} sx={{paddingTop: '22px', paddingBottom: '32px', paddingLeft: '20px'}}>
                <Grid2 size={12} >
                    <Typography fontSize = '32px'>{speaker.name}</Typography>
                </Grid2>
                <Grid2 size={12}>
                    <Typography fontSize = '18px' mb={1.6}>
                        <Box dangerouslySetInnerHTML = {{ __html: speaker.description }}></Box>
                    </Typography>
                </Grid2>
                <Grid2 size={12} >
                    <Typography color={auxiliaryText} fontSize = '18px'>

                    </Typography>
                </Grid2>


            </Grid2>
        </Grid2>
        if (dimensions.isTablet ) return <Grid2 container spacing={0} rowSpacing={0}  >
            <Grid2 size={2.5} sx={{
                paddingTop: '32px',
                paddingBottom: '16px',
                paddingLeft: '32px',
                marginRight: '20px',

            }}>
                <Avatar  variant={'square'}
                         sx={{width: 120, height: 120, borderRadius: radius}}
                >
                    {speakerImgUrl ? <Box width={'120px'}
                                          height={'120px'}
                                          sx={{
                                              backgroundImage: `url(${speakerImgUrl})`,
                                              backgroundSize: 'cover'
                                          }}>

                    </Box> : <AccountBoxIcon/>}

                </Avatar>
            </Grid2>
            <Grid2 container size={9} sx={{paddingTop: '32px', paddingBottom: '32px' }}>
                <Grid2 size={12}  >
                    <Typography fontSize = '28px' >{speaker.name}</Typography>
                </Grid2>
                <Grid2 size={12}>
                    <Typography fontSize = '18px' mb = {1.6}>
                        <Box dangerouslySetInnerHTML = {{ __html: speaker.description }}></Box>
                    </Typography>
                </Grid2>

            </Grid2>
        </Grid2>
        if (dimensions.isMobile) return <CardContent>
            <Grid2 container>
                <Grid2 item size={4}>
                    <Avatar  variant={'square'}
                             sx={{width: 64, height: 64, borderRadius: radius}}
                    >
                        {speakerImgUrl ? <Box width={'64px'}
                                              height={'64px'}
                                              sx={{
                                                  backgroundImage: `url(${speakerImgUrl})`,
                                                  backgroundSize: 'cover'
                                              }}>

                        </Box> : <AccountBoxIcon/>}

                    </Avatar>
                </Grid2>
                <Grid2 item size={8}>
                    <Typography fontSize = '20px'>{speaker.name}</Typography>
                    <Typography className={'speaker-position'} fontSize = '15px'>
                        <Box dangerouslySetInnerHTML = {{ __html: speaker.description }}></Box>
                    </Typography>
                </Grid2>
            </Grid2>
        </CardContent>
    }

    return <CardContent className={'hoverable'}
                 sx={{
                     padding: 0,
                     marginBottom: '30px',
                     position: 'relative',
                     backgroundColor: 'white',
                     borderRadius: radius,

                 }}>
        {renderContents()}
    </CardContent>
}

export default AboutSpeakersSection;