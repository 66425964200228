import {Box, Typography} from "@mui/material";
import './mainHeader.css';
import img from '../../assets/images/logowhite.svg'


const MainHeader = () => {
    return (
        <Box sx={{

        }} className={'main-header'}>
            <img
                alt=''
                className={'img-class'}
                src={img}/>
            <Typography
                sx={{
                    fontSize: {
                        xs: '40px',
                        sm: '40px',
                        md: '40px',
                        lg: '50px',
                        xl: '50px',
                    },
                    paddingTop: {
                        xs: '32px',
                        sm: '30px',
                        md: '100px',
                        lg: '124px',
                        xl: '124px',
                    },
                    paddingLeft: {
                        xs: '20px',
                        sm: '30px',
                        md: '50px',
                        lg: '200px',
                        xl: '200px',
                    },
                    marginBottom: {
                        xs: '12px',
                        sm: '10px',
                        md: '20px',
                        lg: '20px',
                        xl: '20px',
                    },
                    lineHeight: {
                        xs: '40px',
                    },
                    width: {
                        sm: '400px',
                        xl: '520px'
                    }
                }}
                className={'main-header__text-header'}>Афиша мероприятий</Typography>
            <Typography
                sx={{fontSize: {
                        xs: '16px',
                        sm: '18px',
                        md: '18px',
                        lg: '18px',
                        xl: '18px',
                    },
                    paddingLeft: {
                        xs: '20px',
                        sm: '30px',
                        md: '50px',
                        lg: '200px',
                        xl: '200px',
                    },
                    maxWidth: {
                        xs: '92%',
                        sm: '50%',
                    }

                }}
                className={"main-header__text-description"}>
                Уроки проходят в рамках проекта ЦБ РФ «Онлайн уроки финансовой грамотности»
            </Typography>

        </Box>
    )
}

export default MainHeader;