import {CardContent, Grid2, Typography} from "@mui/material";
import './calendarEventDetails.css';
import {darkFont, radius} from "../../utils/cssUtils";
import IconAndText, {ICON_TYPE} from "../IconAndText/IconAndText";

const CalendarEventDetails = ({name, time, location}) => {
    return (
        <CardContent className={'hoverable'}
                     sx={{
                         backgroundColor: 'white',
                         marginBottom: {xl:'16px', sm: '5px'},
                         paddingTop: {xl:'24px', sm: '10px'},
                         paddingLeft: {xl:'40px', sm: '20px'},
                         paddingRight: {xl:'40px', sm: '10px'},
                         borderRadius: radius,
                         minHeight: '9vh'
                     }}>
            <Typography variant="h5" fontSize={{xl:'24px', sm: '20px'}} color={darkFont}>
                {name}
            </Typography>
            <Grid2 container sx={{width: {xs: '75%', xl:'25%', lg: '50%', }, paddingTop: '16px'}}>
                <Grid2 size={6}>
                    <IconAndText
                        icon={ICON_TYPE.time}
                        text={time}/>
                </Grid2>
                <Grid2 size={6}>
                    <IconAndText
                        icon={ICON_TYPE.location}
                        text={location}/>
                </Grid2>
            </Grid2>
        </CardContent>
    )
}

export default CalendarEventDetails;