import React, {useContext} from "react";
import {Box, Button, Grid2, Typography} from "@mui/material";
import {auxiliaryText, getRightClass} from "../../utils/cssUtils";
import AboutEventSection from "../aboutEventSection/AboutEventSection";
import AboutSpeakersSection from "../aboutSpeakersSection/AboutSpeakersSection";
import './eventBody.css';
import {Context} from "../../App";


const
    EventBody = ({eventData}) => {


    const {dimensions} = useContext(Context)


    const renderEventDetails = () => {
        return <Grid2 container className={'mainEvents'} >
            {eventData.eventmonth && <Grid2 item size={{xl: 2.5, sm: 2.5, xs: 12}} className={getRightClass(
                'main-eventdetailsgrid-mobile',
                'main-eventdetailsgrid-tablet',
                'main-eventdetailsgrid-desktop',
                dimensions
            )}>
                <Box>
                    <Typography fontSize={{xl: '38px', sm: '28px', xs: '38px'}} className={'aboutevents'}>
                        {eventData.eventmonth}
                    </Typography>
                </Box>
                <Box>
                    <Typography fontSize={{xl: '20px', sm: '16px', xs: '20px'}} color={auxiliaryText}>
                        Дата
                    </Typography>
                </Box>
            </Grid2>}
            {eventData.eventtime && <Grid2 size={{xl: 2, sm: 2.5, xs: 12}} className={getRightClass(
                'main-eventdetailsgrid-mobile',
                'main-eventdetailsgrid-tablet',
                'main-eventdetailsgrid-desktop',
                dimensions
            )}>
                <Box>
                    <Typography className={'aboutevents'} fontSize={{xl: '38px', sm: '28px', xs: '38px'}}>
                        {eventData.eventtime}
                    </Typography>
                </Box>
                <Box>
                    <Typography fontSize={{xl: '20px', sm: '16px', xs: '20px'}} color={auxiliaryText}>Местное
                        время</Typography>
                </Box>
            </Grid2>}
            {eventData.eventtype && <Grid2 item size={{xl: 3, sm: 3, xs: 12}} className={getRightClass(
                'main-eventdetailsgrid-mobile',
                'main-eventdetailsgrid-tablet',
                'main-eventdetailsgrid-desktop',
                dimensions
            )}>
                <Box>
                    <Typography className={'aboutevents'} fontSize={{xl: '38px', sm: '28px', xs: '38px'}}>
                        {eventData.eventtype}
                    </Typography>
                </Box>
                <Box>
                    <Typography fontSize={{xl: '20px', sm: '16px', xs: '20px'}} color={auxiliaryText}>
                        Формат
                    </Typography>
                </Box>
            </Grid2>}
            {eventData.location && <Grid2 item size={{xl: 4, sm: 4, xs: 12}}>
                <Box>
                    <Typography className={'aboutevents'} fontSize={{xl: '38px', sm: '27px', xs: '38px'}}>
                        {eventData.location}
                    </Typography>
                </Box>
                <Box>
                    <Typography fontSize={{xl: '20px', sm: '16px', xs: '20px'}} color={auxiliaryText}>
                        Город
                    </Typography>
                </Box>
            </Grid2>}
        </Grid2>
    }
    const renderRegisterButton = () => {
        const  redirectToRegistration = () => {
            return window.open(`${eventData.link}`, '_blank');
        }
        return <Button className={getRightClass(
            'button__body-mobile',
            'button__body-tablet',
            'button__body-desktop',
            dimensions
        )}
                       fullWidth={!!dimensions.isMobile}
                       variant={'contained'}
        onClick = {redirectToRegistration}
        >
            Зарегистрироваться
        </Button>
    }


    return (
        <Box className={getRightClass(
            'main-body-mobile',
            'main-body-tablet',
            'main-body-desktop',
            dimensions
        )}>
            {renderEventDetails()}
            {eventData ? <AboutEventSection eventData={eventData}/> : null}
            {eventData.speaker ? <Box pb={5}>
                <Typography className={'speakers'} sx={{paddingTop: 7, paddingBottom: 4}} variant={'h3'}>
                    Спикеры
                </Typography>
                {Object.values(eventData.speaker).map(speaker => {
                    return <AboutSpeakersSection speaker={speaker}/>
                })
                }
            </Box> : null}
            {eventData.link ? renderRegisterButton() : null}
        </Box>
    )

}

export default EventBody;