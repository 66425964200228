import {Box, Grid2, MenuItem, Select, Typography} from "@mui/material";
import {darkFont} from "../../utils/cssUtils";
import {useEffect, useRef, useState} from "react";

export const FilterItem = ({id, filterItem, handleFilterCriterionChange, filterItemFilterValueMap, setFilterItemFilterValueMap, isMacAir, isTablet}) => {

    const handleItemFilterValueMapChange = (value) => {
        let newMap = {...filterItemFilterValueMap};
        newMap[filterItem.id] = value;
        setFilterItemFilterValueMap(newMap);
    }

    const selectRef = useRef(null); // Ref to get the Select DOM element
    const [selectWidth, setSelectWidth] = useState(0); // State to store the width

    useEffect(() => {
        // Get the width of the Select component once it's rendered
        if (selectRef.current) {
            const width = selectRef.current.getBoundingClientRect().width;
            setSelectWidth(width);
        }
    }, []);


    return <Grid2 item size={{xl:12, xs:12, sm: 2.4}}
           key={id}
           sx={{
               height: 74,
               borderRadius: '8px',
               display: "flex",
               flexDirection: "column",
               justifyContent: "space-between",
               marginBottom: {xl:'20px', sm: 0},
               backgroundColor: 'white',
               marginRight:{xl: 0}
           }}
    >
        <Box sx={{
            marginBottom: '16.5px',
            marginTop: '16.5px',
            marginLeft: '18px',
            paddingRight: '10px'
        }}>
            <Typography variant="subtitle1" noWrap>
                <Box className={'filter-name-text'} sx={{
                    overflowX: 'hidden',
                    textOverflow: 'ellipsis',
                    paddingRight: '10px',
                }}>
                    {filterItem.name}
                </Box>
            </Typography>
            <Select
                ref={selectRef}
                value={filterItemFilterValueMap[filterItem.id]}
                defaultValue={-1}
                displayEmpty
                variant="standard"
                disableUnderline
                onChange={(e) => {
                    handleFilterCriterionChange(e.target.value, filterItem.id);
                    handleItemFilterValueMapChange(e.target.value)
                }}
                MenuProps={{
                    PaperProps: {
                        style: {
                            maxHeight: 200, // Set max height for the menu
                            overflowY: 'auto', // Enable vertical scrolling
                        },
                    },
                }}
                sx={{width: '93%', color: darkFont}}
            >
                <MenuItem selected value={-1}
                          sx={{color: darkFont}}>
                    Не выбрано
                </MenuItem>
                {Object.values(filterItem.options).map(option => (
                    <MenuItem key={option.id} value={option.id}
                              sx={{
                                  whiteSpace: 'normal',          // Allow wrapping to new lines
                                  overflowWrap: 'break-word',    // Break text when necessary
                                  wordBreak: 'break-word',       // Break long words
                                  maxWidth: (isTablet || isMacAir) ? '100%' : `${selectWidth}px`,              // Ensure it doesn't exceed Select width
                                  width: (isTablet || isMacAir) ? '100%' : `${selectWidth}px`,              // Ensure it doesn't exceed Select width
                                  color: darkFont,
                              }}>
                        {option.name}
                    </MenuItem>
                ))}
            </Select>
        </Box>
    </Grid2>
}