import {Fragment} from "react";
import MainHeader from "../mainHeader/MainHeader";
import MainBody from "../mainBody/MainBody";
import MainFooter from "../mainFooter/MainFooter";

const EventsPage = () => {
    return (
        <Fragment>
            <MainHeader/>
            <MainBody/>
            <MainFooter/>
        </Fragment>
    )
}

export default EventsPage;