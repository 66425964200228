import {Avatar, Box, Button, CardContent, Chip, Grid2, Link, Typography} from "@mui/material";
import React, {Fragment} from "react";
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import './eventsDetails.css';
import {auxiliaryText, backgroundColor, darkFont, radius} from "../../utils/cssUtils";
import IconAndText, {ICON_TYPE} from "../IconAndText/IconAndText";
import {useNavigate} from "react-router-dom";

const EventsDetails = ({data, isMobile}) => {

    const navigate = useNavigate();

    const renderDateSection = () => {

        return <Fragment className={'date-section-primary'}>
            <Typography variant={'h1'}>
                <Box className={'date-primary'} sx={{
                    fontSize: '80px',
                }}>
                    {data.date}
                </Box>
            </Typography>
            <Typography variant={'h5'}>
                <Box className={'month-name-primary'} sx={{
                    color: darkFont,
                    fontSize: '24px'
                }}>
                    {data.month}
                </Box>
            </Typography>
            <Box sx={{marginLeft: {xl: '25%', sm: '10%'}, marginRight: {xl: '25%', sm: '15%'}, paddingTop: '32px'}}>
                <IconAndText
                    icon={ICON_TYPE.time}
                    text={data.time}/>
            </Box>
        </Fragment>
    }

    const renderMobileDateSection = () => {
        return <Fragment>
            <Grid2 container={2}>
                <Typography variant={'h1'}>
                    <Grid2 item size={3} className={'date-primary'} sx={{
                        fontSize: '80px',
                    }}>
                        {data.date}
                    </Grid2>
                </Typography>
                <Grid2 container={10}>
                    <Typography variant={'h5'}>
                        <Grid2 item size={12} className={'month-name-primary'} sx={{
                            color: darkFont,
                            fontSize: '24px',
                            paddingTop: '9px',
                            paddingLeft: '9px',
                        }}>
                            {data.month}
                        </Grid2>
                    </Typography>
                    <Grid2 item size={7} sx={{paddingLeft: '9px', paddingBottom: '20px'}}>
                        <IconAndText sx={{display: 'flex', alignItems: 'center'}}
                                     icon={ICON_TYPE.time}
                                     text={data.time}/>
                    </Grid2>
                </Grid2>
            </Grid2>
        </Fragment>
    }

    const renderButtonsBlock = (data) => {
        const  redirectToRegistration = () => {
            return window.open(`${data.link}`, '_blank');
        }

        const handleButtonClick = () => {
            navigate (`/${data.hash}`)
        }

        return <Fragment >
            <Button variant={'contained'}
                    className={`event-card-general-button-left 
                    ${isMobile ? 'event-card-general-button-xs-left' : 'event-card-general-button-xl-left'}`}
                    onClick={handleButtonClick}
            >
                Подробнее
            </Button>
                <Button variant={'outlined'}
                        className={`event-card-general-button 
                ${isMobile ? 'event-card-general-button-xs' : 'event-card-general-button-xl'}`}
                            onClick = {redirectToRegistration}
                >
                    Записаться
                </Button>
        </Fragment>
    }
    const renderCardText = () => {
        return <Fragment>
            <Typography variant={'h4'}>
                <Link color={darkFont} href={`${data.hash}`} underline="none">
                    <Box className={'event-card-title-text'} class sx={{
                        color: darkFont
                    }}>
                        {data.name}
                    </Box>
                </Link>


            </Typography>
            <Typography variant={'h6'} sx={{paddingBottom: '20px'}}>
                <Box className={'event-card-description-text'} sx={{
                    color: auxiliaryText
                }}>
                    {data.description}
                </Box>
            </Typography>
        </Fragment>
    }

    const renderImageAndText = () => {

        return <Box>
            <Avatar  variant={'square'}
                     sx={{width: 64, height: 64, borderRadius: radius}}
            >
                {data.image ? <Box width={64}
                                   height={64}
                                   sx={{
                                       backgroundImage: `url(${data?.image})`,
                                       backgroundSize: 'cover'
                                   }}>

                </Box> : <AccountBoxIcon/>}
            </Avatar>

            <Typography variant={'h5'}><Box
                sx={{fontSize: '18px', fontWeight: 500, color: darkFont}}>{data.speakerName}</Box></Typography>
            <Typography variant={'h6'} sx={{paddingBottom: '20px'}}>
                <Box sx={{fontSize: '16px', fontWeight: 300, color: darkFont}}>
                    {data.speakerPosition}
                </Box>
            </Typography>
        </Box>
    }
    const renderMobileImageAndText = () => {
        return <Fragment>
            <Grid2 container size={12}>
                <Grid2 item size={3}>
                    <Avatar src={!data.image ? '' : data?.image?.imgUrl} variant={'square'}
                            sx={{width: 64, height: 64, borderRadius: radius}}
                    >
                        <AccountBoxIcon/>
                    </Avatar>
                </Grid2>
                <Grid2 container size={9}>
                    <Typography variant={'h5'}><Box
                        sx={{fontSize: '18px', fontWeight: 500, color: darkFont}}>{data.speakerName}</Box></Typography>
                    <Typography variant={'h6'} sx={{paddingBottom: '20px'}}>
                        <Box sx={{fontSize: '16px', fontWeight: 300, color: darkFont}}>
                            {data.speakerPosition}
                        </Box>
                    </Typography>
                </Grid2>
            </Grid2>
        </Fragment>

    }


    return (
        <CardContent className={'hoverable'}
                     sx={{
                         padding: 0,
                         marginBottom: '30px',
                         position: 'relative',
                         backgroundColor: 'white',
                         borderRadius: radius,
                         '&::before, &::after': {
                             content: '""',
                             position: 'absolute',
                             top: 0,
                             bottom: 0,
                             width: '1px',
                             backgroundColor: backgroundColor,
                         },
                         '&::before': {
                             left: '19%',
                         },
                         '&::after': {
                             left: '76%',
                         },
                         '&:hover::before, &:hover::after': {
                             top: 1,
                             bottom: 1,
                         },
                         '@media (max-width: 600px)': {
                             '&::before, &::after': {
                                 display: 'none',
                             }
                         }
                     }}>
            <Chip label={data.chip} className={'custom-chip'}></Chip>
            <Grid2 container spacing={{xl: 2, sm: 2, xs: 12}} sx={{minHeight: '26vh'}}
                   rowSpacing='0'
                   gap='0'>
                <Grid2 size={{xl: 2.5, sm: 2.5, xs: 12}} sx={{
                    paddingTop: '16px',
                    paddingBottom: '16px',
                    paddingLeft: '16px',

                }}>
                    {isMobile ? renderMobileDateSection() : renderDateSection()}
                </Grid2>
                <Grid2 size={{xl: 6.5, sm: 6.5, xs: 12}} sx={{
                    paddingTop: {xl: '32px', sm: '32px', xs: '0'},
                    paddingBottom: {xl: '32px', lg: '20px', sm: '20px', xs: '0'},
                    paddingLeft: "20px",
                    paddingRight: '20px'
                }}
                >
                    {renderCardText()}
                    {!isMobile ? renderButtonsBlock(data) : ''}
                </Grid2>
                <Grid2 size={{xl: 3, sm: 3, xs: 12}} sx={{
                    paddingTop: {xl: '32px', sm: '32px', xs: '0'},
                    paddingBottom: '32px',
                    paddingRight: {xl: '32px', sm: '32px', xs: '20px'},
                    paddingLeft: {xl: '50px', sm: '50px', xs: '20px'},
                }}>
                    {isMobile ? renderMobileImageAndText() : renderImageAndText()}
                    {isMobile ? renderButtonsBlock(data) : null}
                </Grid2>
            </Grid2>
        </CardContent>
    )
}

export default EventsDetails;