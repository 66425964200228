import {Box, Grid2, Typography} from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import {auxiliaryText} from "../../utils/cssUtils";

export const ICON_TYPE = {
    time: <AccessTimeIcon sx={{color: auxiliaryText, position: 'absolute', height: {xl:'27px', xs: '20px'}}}/>,
    location: <FmdGoodOutlinedIcon sx={{color: auxiliaryText, position: 'absolute'}}/>
}

const IconAndText = ({icon, text}) => {

    if (!text) {
        return null;
    }

    return <Grid2 container>
        <Grid2 size={4} sx={{position: 'relative', bottom: 0, display: 'flex', alignItems: 'center'}} spacing={2}>
            {icon}
        </Grid2>
        <Grid2 size={8}>
            <Typography component={'span'}>
                <Box sx={{
                    fontSize: '18px',
                    color: auxiliaryText,
                    whiteSpace: 'nowrap',
                    overflow: 'visible'
                }}>{text}</Box>
            </Typography>
        </Grid2>
    </Grid2>
}

export default IconAndText;