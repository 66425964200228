import './mainBody.css';
import {useEffect, useState} from "react";
import CalendarSection from "../calendarSection/CalendarSection";
import EventsSection from "../eventsSection/EventsSection";
import FilterSection from "../filterSection/FilterSection";
import {Box, CircularProgress, Grid2, Typography, useMediaQuery} from "@mui/material";
import Grid from '@mui/material/Grid2';
import {backgroundColor} from "../../utils/cssUtils";
import CustomButtonGroup, {PRESSED_BUTTON} from "../customButtonGroup/CustomButtonGroup";
import jsonService from "../../services/jsonService";
import {getMonthYearString} from "../../utils/dateUtils";
import NotFoundComponent from "../notFoundComponent/NotFoundComponent";

const MainBody = () => {

    const [data, setData] = useState({});
    const [eventsLoading, setEventsLoading] = useState(true);
    const [speakersImages, setSpeakersImages] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState(getMonthYearString(new Date()));

    useEffect( () => {
        async function func() {
            if (selectedMonth) {
                let monthEventsData = await jsonService.getMonthEventsData(selectedMonth) ?? [];
                let filtersData = await jsonService.getFiltersData();
                setEventsLoading(false);
                let eventsData = Object.values(monthEventsData);
                setData({
                    events: Object.values(eventsData),
                    filters: filtersData
                })

                let speakers = eventsData.map(i => i.firstspeaker);

                let allSpeakerImages = await jsonService.getAllSpeakerImagesMap(speakers);
                setSpeakersImages(allSpeakerImages);

            }
        }
        func();
    }, [selectedMonth])


    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(min-width:601px) and (max-width:900px)');
    const isMacAir = useMediaQuery('(min-width:901px) and (max-width:1300px)');

    const [filterCriteria, setFilterCriteria] = useState([])
    const [pressedButton, setPressedButton] = useState(PRESSED_BUTTON.NONE);

    if (!data) {
        return null;
    }

    return ( eventsLoading ?  <Box
            sx={{
                display: "grid",
                placeItems: "center",
                height: "100vh",
            }}
        >
            <CircularProgress />
        </Box>
     :
        <Box className={'main-body'}
             sx={{
                 paddingRight: {
                     xs: "16px",
                     sm: '40px',
                     md: '150px',
                     lg: '200px',
                     xl: '200px',
                 },
                 paddingLeft: {
                     xs: '16px',
                     sm: '40px',
                     md: '150px',
                     lg: '200px',
                     xl: '200px',
                 },
             }}
        >
            <CalendarSection
                isMobile={isMobile}
                data={data}
                setSelectedMonth={setSelectedMonth}
            />
            {data?.events?.length > 0 ? <Grid2 container size={12} id={'outer mobile'}>
                <Grid2 item size={{xl: 12, lg: 8, sm: 8}}>
                    <Typography variant={'h3'}
                                sx={{
                                    color: "#B6C1DD",
                                    marginTop: {xs: "400px", sm: '0', lg: '0', xl: "80px"},
                                    fontSize: {xs: '40px', sm: 'h5', xl: '50px'},
                                }}>
                        Список мероприятий
                    </Typography>
                </Grid2>
                <Grid2 item size={{xl: 12, lg: 4, sm: 4}} width={'100%'}>
                    {(isTablet || isMacAir) && <CustomButtonGroup
                        setPressedButtonInGroup={setPressedButton}
                        pressedButtonInGroup={pressedButton}
                        buttonName1={'Предстоящие'} buttonName2={'Прошедшие'}/>}
                </Grid2>
                <Grid container spacing={3} sx={{paddingTop: '40px'}} width={'100%'}>
                    <Grid2 size={{xs: 12, lg: 12, sm: 12, xl: 2.8}}
                           sx={{
                               position: 'sticky',
                               backgroundColor: "#f3f7fa",
                               zIndex: 2,
                               top: {xl: '20px', xs: '0'},
                               maxHeight: '80vh',
                               overflowX: {xs: 'auto', xl: 'none'},
                           }}>
                        <FilterSection data={data}
                                       isMobile={isMobile}
                                       isTablet={isTablet}
                                       isMacAir={isMacAir}
                                       pressedButton={pressedButton}
                                       setPressedButton={setPressedButton}
                                       setFilterCriteria={setFilterCriteria}
                                       filterCriteria={filterCriteria}/>
                    </Grid2>
                    <Grid2 size={{xl: 9.2, lg: 12, sm: 12, xs: 12}}>
                        <EventsSection isMobile={isMobile}
                                       speakersImages={speakersImages}
                                       data={data}
                                       beforeAfterState={pressedButton}
                                       filterCriteria={filterCriteria}/>
                    </Grid2>
                </Grid>
            </Grid2> : isMobile ? <NotFoundComponent
                text={'Мероприятий в этом месяце не запланировано'}
            /> : null}
        </Box>
    )
}

export default MainBody;