import {Box, Button, Grid2,} from "@mui/material";
import './eventsSection.css';
import {useEffect, useState} from "react";
import EventsDetails from "../eventsDetails/EventsDetails";
import {addMonths, getDayAtStartOfDay, getGenitiveMonthName, subtractMonths} from "../../utils/dateUtils";
import {PRESSED_BUTTON} from "../customButtonGroup/CustomButtonGroup";
import {auxiliaryText, backgroundColor, radius} from "../../utils/cssUtils";

const EventsSection = ({filterCriteria, data, beforeAfterState, isMobile, speakersImages}) => {
    const [indexOfVisibleArray, setIndexOfVisibleArray] = useState(3);
    const [filteredData, setFilteredData] = useState([]);
    const [visibleFilteredData, setVisibleFilteredData] = useState([]);

    /* Фильтрация Списка мероприятий при изменении filterCriteria и beforeAfterState */
    useEffect(() => {
        let localData = filterDataByCriteriaArray(filterCriteria, data);
        localData = filterByBeforeAfter(localData, beforeAfterState);

        setFilteredData(localData)
        setVisibleFilteredData(localData.slice(0, indexOfVisibleArray))

    }, [filterCriteria, beforeAfterState, data]);

    /* Фильтр по критериям */
    const filterDataByCriteriaArray = (selectedCriteriaArray, data) => {
        let localFilteredData = data.events;

        selectedCriteriaArray.forEach(criterion => {
            localFilteredData = localFilteredData.filter(event => {

                for (let filterId in event.filters) {

                    let options = event.filters[filterId];

                    if ((filterId == criterion.filterId && options.includes(criterion.optionId))
                        || criterion.optionId === -1) {
                        return event
                    }
                }
            });
        })

        return localFilteredData;
    }

    /* Фильтр для кнопок "предстоящие" и "прошедшие" */
    const filterByBeforeAfter = (data, beforeAfterState) => {

        if (beforeAfterState === PRESSED_BUTTON.NONE) {
            return data;
        }
        return data.filter(e => {

            let currentDate = getDayAtStartOfDay(new Date()).getTime();
            let eventDate = new Date(e.formatdate).getTime();
            return beforeAfterState === PRESSED_BUTTON.AFTER ?
                currentDate < eventDate && addMonths(new Date(), 6) >= eventDate
                : currentDate > eventDate && subtractMonths(new Date(), 3) <= eventDate;
        })
    }

    /* Логика для кнопки "Показать еще" */
    const handleClick = () => {

        let localData = filterDataByCriteriaArray(filterCriteria, data);
        localData = filterByBeforeAfter(localData, beforeAfterState);

        setFilteredData(localData)
        setVisibleFilteredData(localData.slice(0, indexOfVisibleArray + 3));
        setIndexOfVisibleArray(indexOfVisibleArray + 3);
    }


    return (
        <Box id={'events section'}>
            {visibleFilteredData.map((d, index) => {
                const day = d.formatmonth.split(' ')[0];
                const month = d.formatmonth.split(' ')[1];
                const img  = speakersImages.filter(si => si.hash === d.firstspeaker.hash)[0]
                return <EventsDetails
                    isMobile={isMobile}
                    key={index}
                    data={{
                        link: d.link,
                        hash: d.hash,
                        description: d.intro,
                        time: d.formattime,
                        name: d.name,
                        date: day,
                        month: month,
                        chip: d.typename,
                        image: d.firstspeaker.avatar,
                        speakerName: d.firstspeaker.name,
                        speakerPosition: d.firstspeaker.description,
                    }}


                />

            })}
            {visibleFilteredData.length === 0 && <Grid2 item width={'100%'} size={12}></Grid2>}
            {filteredData.length > indexOfVisibleArray && <Button
                sx={{
                    height: '52px',
                    backgroundColor: backgroundColor,
                    color: auxiliaryText,
                    borderRadius: radius,
                    mb: 4,
                }}
                variant={'contained'}
                fullWidth
                onClick={handleClick}>Показать еще</Button>}
        </Box>
    )
}

export default EventsSection;