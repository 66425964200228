import data from '../data/data.json';
import {getBaseUrl, getBlobData, getData} from "../utils/httpUtils";

const api = {
    getDataForMainPage: () => {
        return data;
    },
    getEventByUrl: async (hash) => {
        try {
            return await getData(`${getBaseUrl()}/files/events/${hash}/data.json`);
        } catch (error) {
            console.error('Error fetching data:', error);
            return {};
        }
    },
    getMonthByUrl: async (month) => {
        try {
            return await getData(`${getBaseUrl()}/files/events/months/${month}.json`);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    },
    getFiltersByUrl: async () => {
        try {
            return await getData(`${getBaseUrl()}/files/filters.json`);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    },
    getSpeakerImageByUrl: async (relativePath) => {
        try {
            return await getBlobData(`${getBaseUrl()}${relativePath}`);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    },
}

export default api;
