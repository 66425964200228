import {Box, Grid2} from "@mui/material";
import './calendarSection.css';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import CustomCalendar from "../customCalendar/CustomCalendar";
import {useState} from "react";
import {getClosestDateWithEvents} from "../../utils/dateUtils";
import CalendarEvents from "../calendarEvent/CalendarEvents";
import NotFoundComponent from "../notFoundComponent/NotFoundComponent";

const CalendarSection = ({data, setSelectedMonth, isMobile}) => {

    dayjs.locale('ru');

    /* Если сегодня нет запланированных мероприятий, то показываем ближайшую дату с событиями  */


    const [selectedDay, setSelectedDay] = useState(getClosestDateWithEvents(data));


    /*Фильтруем данные по выбранному дню в календаре*/
    const filterDataBySelectedDay = (selectedDay, data) => {
        if (selectedDay == null) {
            return [];
        }
        const formattedSelectedDay = `${selectedDay.getFullYear()}-${String(selectedDay.getMonth() + 1).padStart(2, '0')}-${String(selectedDay.getDate()).padStart(2, '0')}`
        return data.events.filter(event => event.formatdate == formattedSelectedDay);
    }

    const filteredData = filterDataBySelectedDay(selectedDay, data);

    return (
        <Box className={isMobile ? 'mobile_wrapper' : "calendar-wrapper"}>
            <Grid2 container width={'100%'} spacing={'20px'}>
                <Grid2
                    size={{xs: 12, sm: 6, md: 10, lg: 4.35, xl: 4.35}}
                    sx={{
                    marginRight: {xs: 0, sm: 0, md: 0, lg: 0, xl: 0},
                }}
                >
                    <CustomCalendar data={data}
                                    selectedDay={selectedDay}
                                    setSelectedDay={setSelectedDay}
                                    setSelectedMonth={setSelectedMonth}
                    />
                </Grid2>
                <Grid2 size={{xs: 12, sm: 6, md: 10, lg: 7.65, xl: 7.65}}
                       sx={{ height:{
                                   xs:  '0',
                                   sm:  '30vh',
                                   md:  '50vh',
                                   lg:  '44vh',
                                   xl:  '44vh',
                               },
                       }}
                >
                    {filteredData.length > 0 ? <CalendarEvents sx={{}}
                                     selectedDay={selectedDay}
                                     filteredData={filteredData}/> : isMobile ? null :
                        <NotFoundComponent
                            text={'Мероприятий в этом месяце не запланировано'}
                        />
                    }
                </Grid2>
            </Grid2>
        </Box>

    )
}

export default CalendarSection;